import Image from 'next/image'
import React, {forwardRef} from 'react'

function getStrapiImage(data: any,
                        size: string | null = null) {
	if (data) {
		if (size) {
			return data.formats[size]
				??
				data
		} else {
			return data
		}
	}
	return null
}

const Z_Strapi_Image = forwardRef<HTMLImageElement, {
	image: any,
	size?: string | null,
	fill?: boolean,
	alt?: string | null,
	className?: string | undefined,
	sizes?: string,
	priority?: boolean
}>(({
	    image,
	    size = null,
	    fill = false,
	    alt = null,
	    className = undefined,
	    sizes = '(max-width: 768px) 100vw,(max-width: 1200px) 50vw,33vw',
	    priority = false,
    },
    ref) => {


	const strapiImage = getStrapiImage(
		image?.data?.attributes ??
		image?.attributes,
		size,
	)

	const moreAttributes = fill
		?
		{}
		:
		{
			width:  strapiImage?.width,
			height: strapiImage?.height, // sizes:  '(max-width: 768px) 100vw,(max-width: 1200px) 50vw, 33vw',
		}

	const altProp = alt
		??
		strapiImage?.alternativeText ??
		''

	if (strapiImage) {
		return (
			<Image
				src={process.env.NEXT_PUBLIC_SITE_API_LINK
					+
					strapiImage.url}

				alt={altProp}
				fill={fill}

				/*Default sizes*/
				sizes={sizes}
				priority={priority}

				className={className}
				ref={ref}
				{...moreAttributes}
			/>
		)
	}

	return null
})

Z_Strapi_Image.displayName =
	'Z_Strapi_Image'

export default Z_Strapi_Image
