import styles from '@/styles/components/main_page/WhyChooseUs.module.sass'
import {
    createRef,
    useEffect,
    useMemo,
    useState,
} from 'react'
import cn from 'classnames'
// @ts-ignore
import gsap from 'gsap/dist/gsap'
import {
    Swiper,
    SwiperSlide,
} from 'swiper/react'
// Import Swiper styles
import 'swiper/css'
//region Images
import {StrapiImage} from '@/models/strapiImage'
import Z_Strapi_Image from '@/components/Z_Strapi_Image'
//endregion

export type WhyChooseUsData = {
    title: string
    subtitle: string
    list: [{
        title: string
        description: string
        image: StrapiImage
    }]
}

const WhyChooseUs = ({data}: {
    data: WhyChooseUsData
}) => {

    const itemsData = useMemo(() => data.list.map((item,
                                                   index) => {
        return {
            item: item,
            ref: createRef<HTMLImageElement>(),
        }
    }), [])

    //region States
    // Current image index
    const [oldImageIndex, setOldImageIndex] = useState(0)
    const [currentImageIndex, setCurrentImageIndex] = useState(0)
    const timeline = useMemo(() => gsap.timeline(
        {
            defaults: {
                duration: .3,
            },
        },
    ), [])

    // Window size is mobile
    const [isMobile, setIsMobile] = useState(false)
    useEffect(() => {
        // Set is mobile
        setIsMobile(window.innerWidth <
            768)

        window.addEventListener('resize', () => {
            setIsMobile(window.innerWidth <
                768)
        })
    }, [])
    //endregion

    //region Handlers
    useEffect(() => {
        const currentImage = itemsData[currentImageIndex]
        const oldImage = itemsData[oldImageIndex]
        const currentImageRef = currentImage.ref
        const oldImageRef = oldImage.ref

        timeline.set(oldImageRef.current, {
            zIndex: 1,
        })
            .fromTo(
                currentImageRef.current,
                {
                    xPercent: 100,
                    zIndex: 2,
                },
                {
                    xPercent: 0,
                },
            )
            .set(oldImageRef.current, {
                zIndex: 0,
            })
            .set(currentImageRef.current, {
                zIndex: 1,
            })

        setOldImageIndex(currentImageIndex)

    }, [currentImageIndex])
    //endregion

    return (
        <div className={styles.why_choose_us}>
            {/*Bottom*/}
            {isMobile
                ? <>
                    <div className={styles.top}>
                        {/*Title*/}
                        <h2 className={styles.why_choose_us_title}>Pourquoi choisir Next&nbsp;Step&nbsp;?</h2>

                        {/*Subtitle*/}
                        <h3 className={styles.why_choose_us_subtitle}>Pour révéler<br/>Votre intérieur</h3>
                    </div>

                    {/*List*/}
                    <Swiper
                        slidesPerView={1.2}
                        spaceBetween={32}
                        direction={'horizontal'}

                        className={styles.bottom}
                    >
                        {itemsData.map((itemData,
                                        index) => (
                            <SwiperSlide
                                className={styles.why_choose_us_item}
                                key={index}
                            >
                                {/*Title*/}
                                <h3 className={styles.why_choose_us_item_title}>{index +
                                    1}/ {itemData.item.title}</h3>

                                {/*Description*/}
                                <span className={styles.why_choose_us_item_description}>{itemData.item.description}</span>

                                {/*Image*/}
                                <Z_Strapi_Image
                                    key={index}
                                    ref={itemData.ref}
                                    className={cn(styles.image,
                                        {
                                            [styles.active]: index ===
                                            currentImageIndex,
                                        })}
                                    image={itemData.item.image}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </>
                : <>
                    {/*Left*/}
                    <div className={styles.left}>
                        <div className={styles.top}>
                            {/*Title*/}
                            <h2 className={styles.why_choose_us_title}>Pourquoi choisir Next&nbsp;Step&nbsp;?</h2>

                            {/*Subtitle*/}
                            <h3 className={styles.why_choose_us_subtitle}>Pour révéler<br/>Votre intérieur</h3>
                        </div>

                        {/*List*/}
                        <div className={styles.list}>
                            {itemsData.map((itemData,
                                            index) => (
                                <div
                                    className={styles.why_choose_us_item}
                                    key={index}

                                    onPointerEnter={() => {
                                        // Set next image index
                                        setCurrentImageIndex(index)
                                    }}
                                >
                                    {/*Title*/}
                                    <h4 className={styles.title}>{index +
                                        1}/ {itemData.item.title}</h4>

                                    {/*Description*/}
                                    <span className={styles.description}>{itemData.item.description}</span>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/*Right*/
                    }
                    <div className={styles.right}>
                        {itemsData.map((itemData,
                                        index) => (
                            <Z_Strapi_Image
                                key={index}
                                ref={itemData.ref}
                                className={cn(styles.image,
                                    {
                                        [styles.active]: index ===
                                        currentImageIndex,
                                    })}
                                image={itemData.item.image}
                                fill={true}
                            />
                        ))}
                    </div>
                </>
            }
        </div>
    )
}

export default WhyChooseUs
