import styles from '@/styles/Home.module.sass'
import Header from "@/components/header"
import Footer, {FooterData} from "@/components/footer"
import Image from 'next/image'
import WhyChooseUs, {WhyChooseUsData} from '@/components/main_page/whyChooseUs'
import Presentation from '@/components/main_page/presentation'
//region Your project images
import BottomSection, {BottomSectionData} from '@/components/BottomSection'
import Realisation from '@/components/Realisation'
import {RealisationSmallType} from '@/models/realisation'
//endregion
import {GetStaticProps} from 'next'
import axios from 'axios'
import qs from 'qs'
import Z_Strapi_Image from '@/components/Z_Strapi_Image'
import {
    StrapiImage,
    StrapiImageArray,
} from '@/models/strapiImage'
import Markdown from 'marked-react'
import {getFooterData} from '@/utils/Z_Utils'
import {
    Swiper,
    SwiperSlide,
} from 'swiper/react'
import 'swiper/swiper.css'
import ButtonLink from '@/components/ButtonLink'
import React, {useEffect, useMemo, useState} from 'react'
import Link from 'next/link'
import https from 'https'
import {NextSeo} from 'next-seo'
import Script from "next/script";

export default function Home({
                                 data,
                                 whyChooseUs,
                                 instagramData,
                                 footerData,
                                 time,
                                 firstLoad,
                             }:
                                 {
                                     data: HomeData,
                                     whyChooseUs: WhyChooseUsData,
                                     instagramData: [InstagramData],
                                     footerData: FooterData,
                                     time: Date,
                                     firstLoad?: boolean
                                 },
) {
    const SEO = {
        description: 'Décoratrice intérieure et coach déco à Clermont-Ferrand : transformez votre espace avec style et élégance grâce à des conseils personnalisés et services sur-mesure. Révélez le potentiel de votre intérieur !',
    }

    //region Window width
    const [windowWidth, setWindowWidth] = useState(0)
    const isMobile = useMemo(
        () => {
            return windowWidth <= 765
        },
        [windowWidth],
    )

    useEffect(
        () => {
            setWindowWidth(window.innerWidth)
            window.addEventListener(
                'resize',
                () => {
                    setWindowWidth(window.innerWidth)
                },
            )
        },
        [],
    )
    //endregion

    const fakeInstagramData = [
        {
            permalink: 'https://www.instagram.com/p/Cz8gkKjobUB/',
            media_url: '/images/instagram/inspiration.jpeg',
            caption: 'Inspiration',
        },
        {
            permalink: 'https://www.instagram.com/p/Cz4NIO0LUOf/',
            media_url: '/images/instagram/newOffice.jpeg',
        },
        {
            permalink: 'https://www.instagram.com/p/CzTw9v5I-Xm/',
            media_url: '/images/instagram/newOffice2.jpeg',
            caption: 'New office',
        },
        {
            permalink: 'https://www.instagram.com/p/Cy_IZ3jIOBs/',
            media_url: '/images/instagram/newColor.jpeg',
            caption: 'New color',
        },
        {
            permalink: 'https://www.instagram.com/p/CyGG-bhom-g/',
            media_url: '/images/instagram/avantApres.jpeg',
            caption: 'Avant / Après',
        },
        {
            permalink: 'https://www.instagram.com/p/CyDu0gjotx0/',
            media_url: '/images/instagram/6.jpeg',
        }

    ]

    return (
        <>
            {/*SEO*/}
            <NextSeo {...SEO} />

            {/*Header*/}
            <Header/>

            {/*Main content*/}
            <main className={styles.page_content}>
                {/*Hero*/}
                <div className={styles.hero}>
                    {/*Image container*/}
                    <div className={styles.hero_image_container}>
                        {/*Image*/}
                        <Z_Strapi_Image
                            image={data.hero_banner}
                            size={'full'}
                            fill={true}
                            sizes={''}
                            priority={true}
                        />
                    </div>

                    {/*Content*/}
                    <div className={styles.hero_content}>
                        {/*Left*/}
                        <h1 className={styles.hero_content_left}>
                            {/*Title*/}
                            <span className={styles.hero_title}>{data.title}</span>

                            {/*Subtitle*/}
                            <span className={styles.hero_subtitle}>{data.subtitle}</span>
                        </h1>

                        {/*Right*/}
                        <div className={styles.hero_content_right}>
                            {/*Description*/}
                            <span className={styles.hero_description}><Markdown value={data.description}/></span>

                            {/*Arrow*/}
                            <svg
                                width='64'
                                height='64'
                                viewBox='0 0 64 64'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'

                                className={styles.hero_arrow}
                            >
                                <rect
                                    width='64'
                                    height='64'
                                    rx='32'
                                    fill='#E7EAEE'
                                />
                                <path
                                    d='M31.2929 46.2071C31.6834 46.5976 32.3166 46.5976 32.7071 46.2071L39.0711 39.8431C39.4616 39.4526 39.4616 38.8195 39.0711 38.4289C38.6805 38.0384 38.0474 38.0384 37.6569 38.4289L32 44.0858L26.3431 38.4289C25.9526 38.0384 25.3195 38.0384 24.9289 38.4289C24.5384 38.8195 24.5384 39.4526 24.9289 39.8431L31.2929 46.2071ZM31 18.5L31 45.5L33 45.5L33 18.5L31 18.5Z'
                                    fill='#0B415E'
                                />
                            </svg>
                        </div>
                    </div>
                </div>

                {/*Presentation*/}
                {!isMobile && <Presentation
                    data={{
                        title: data.presentation_title,
                        image: data.presentation_center_image,
                    }}
                    firstLoad={firstLoad}
                />}

                {/*Your project*/}
                <div className={styles.your_project}>
                    {/*Left*/}
                    <div className={styles.your_project_left}>
                        {/*Title*/}
                        <h2
                            className={styles.your_project_title}
                            dangerouslySetInnerHTML={{__html: data.your_project_title}}
                        />

                        {/*Content*/}
                        <div className={styles.your_project_content}>
                            {/*Top citation*/}
                            <span className={styles.your_project_content_citation}><Markdown value={data.your_project_description}/></span>

                            {/*Description*/}
                            <span className={styles.description}><Markdown value={data.your_project_description2}/></span>

                            {/*Our services button*/}
                            <ButtonLink
                                href={'/services'}
                                className={styles.your_project_button}
                                text={'Nos services'}
                            />
                        </div>
                    </div>

                    {/*Right*/}
                    <div className={styles.your_project_right}>
                        {data.your_project_images.data.map((image,
                                                            index) => (
                            <div
                                className={styles.your_project_image_container}
                                key={index}
                            >
                                <Z_Strapi_Image
                                    image={image}
                                    size={'medium'}
                                />
                            </div>
                        ))}
                    </div>
                </div>

                {/*Why choose us*/}
                <WhyChooseUs data={whyChooseUs}/>

                {/*Realisations*/}
                <div className={styles.realisations}>
                    {/*Top*/}
                    <div className={styles.left}>
                        {/*Title*/}
                        <h2
                            className={styles.realisations_title}
                            dangerouslySetInnerHTML={{__html: data.realisations_title}}
                        />

                        {/*Link*/}
                        <ButtonLink
                            href={'/realisations'}
                            className={styles.realisations_link}
                            text={'Voir mes réalisations'}
                        />
                    </div>

                    {/*Bottom*/}
                    <Swiper
                        className={styles.right}

                        breakpoints={{
                            1: {
                                slidesPerView: 1.2,
                                spaceBetween: 16,
                            },
                            768: {
                                slidesPerView: 2.2,
                                spaceBetween: 16,
                            },
                            1024: {
                                slidesPerView: 2.2,
                                spaceBetween: 32,
                            },
                        }
                        }
                    >
                        {data.realisations?.data?.map((realisation,
                                                       index) => (
                            <SwiperSlide
                                key={index}
                            >
                                <Realisation
                                    realisation={realisation}
                                />
                            </SwiperSlide>
                        ))}
                    </Swiper>
                </div>

                {/*Instagram*/}
                <div className={styles.instagram}>
                    {/*Top*/}
                    <div className={styles.instagram_top}>
                        {/*Title*/}
                        <span
                            className={styles.instagram_title}
                            dangerouslySetInnerHTML={{__html: data.instagram_title}}
                        />

                        {/*Link*/}
                        <ButtonLink
                            href={'https://www.instagram.com/nextstep.byfloriane/'}
                            className={styles.realisations_linkinstagram_link}
                            text={'Voir mon instagram'}
                        />
                    </div>

                    {/*Feed*/}
                    {/*<div className={styles.instagram_feed}>*/}
                    {/*	{instagramData?.map((image,*/}
                    {/*						 index) => (*/}
                    {/*		<Link*/}
                    {/*			href={image.permalink}*/}
                    {/*			className={styles.instagram_feed_image}*/}
                    {/*			key={index}*/}
                    {/*		>*/}
                    {/*			<Image*/}
                    {/*				src={image.media_url}*/}
                    {/*				fill={true}*/}
                    {/*				alt={image.caption ??*/}
                    {/*					'Photo instagram'}*/}
                    {/*			/>*/}
                    {/*		</Link>*/}
                    {/*	))}*/}
                    {/*</div>*/}

                    {/*FakeFeed*/}
                    <div className={styles.instagram_feed}>
                        {fakeInstagramData?.map((fakeImage,
                                                 index) => (
                            <Link
                                href={fakeImage.permalink}
                                className={styles.instagram_feed_image}
                                key={index}
                                target={'_blank'}
                            >
                                <Image
                                    src={fakeImage.media_url}
                                    fill={true}
                                    alt={fakeImage.caption ??
                                        'Photo instagram'}
                                />
                            </Link>
                        ))}
                    </div>
                </div>

                {/*Contact*/}
                <BottomSection data={data.bottom_section}/>
            </main>

            {/*Footer*/}
            <Footer data={footerData}/>
        </>
    )
}

//region Get data
type HomeData = {
    title: string
    subtitle: string
    description: string
    hero_banner: StrapiImage

    presentation_title: string
    presentation_center_image: StrapiImage
    presentation_images: StrapiImageArray


    your_project_title: string
    your_project_description: string
    your_project_description2: string
    your_project_images: StrapiImageArray

    realisations_title: string

    instagram_title: string

    realisations: {
        data: RealisationSmallType[]
    }

    bottom_section: BottomSectionData
}

type InstagramData = {
    id: string
    media_url: string
    permalink: string
    caption?: string
}

export const getStaticProps: GetStaticProps = async () => {
    axios.defaults.headers.common['Authorization'] =
        `Bearer ${process.env.API_TOKEN}`
    https.globalAgent.options.rejectUnauthorized =
        false

    //region General data
    const query = qs.stringify({
        fields: [
            /*Hero*/
            'title',
            'subtitle',
            'description',

            /*Presentation*/
            'presentation_title',

            /*Your project*/
            'your_project_title',
            'your_project_description',
            'your_project_description2',

            /*Realisations*/
            'realisations_title',

            /*Instagram*/
            'instagram_title',
        ],
        populate: {
            realisations: {
                limit: 3,
                fields: [
                    'title',
                    'slug',
                    'description',
                ],
                populate: {
                    image: {
                        fields: [
                            'url',
                            'alternativeText',
                            'width',
                            'height',
                            'formats',
                        ],
                    },
                },
            },
            bottom_section: {
                fields: [
                    'title',

                    /*Button*/
                    'button_text',
                    'button_link',
                ],
                populate: {
                    background_image: {
                        fields: [
                            'url',
                            'alternativeText',
                            'width',
                            'height',
                            'formats',
                        ],
                    },
                },
            },

            /*Images*/
            hero_banner: {
                fields: [
                    'url',
                    'alternativeText',
                    'width',
                    'height',
                    'formats',
                ],
            },
            presentation_center_image: {
                fields: [
                    'url',
                    'alternativeText',
                    'width',
                    'height',
                    'formats',
                ],
            },
            presentation_images: {
                fields: [
                    'url',
                    'alternativeText',
                    'width',
                    'height',
                    'formats',
                ],
            },
            your_project_images: {
                fields: [
                    'url',
                    'alternativeText',
                    'width',
                    'height',
                    'formats',
                ],
            },
        },
    }, {encodeValuesOnly: true})
    let data = null

    await axios.get(`${process.env.NEXT_PUBLIC_SITE_API_LINK}/api/page-accueil?${query}`)
        .then(res => {
            data =
                res.data.data.attributes
        })
    //endregion

    //region Why choose us data
    let whyChooseUs = null
    const whyChooseUsQuery = qs.stringify({
        fields: [
            'title',
            'subtitle',
        ],
        populate: {
            list: {
                fields: [
                    'title',
                    'description',
                ],
                populate: {
                    image: {
                        fields: [
                            'url',
                            'alternativeText',
                            'width',
                            'height',
                            'formats',
                        ],
                    },
                },
            },
        },
    }, {encodeValuesOnly: true})

    await axios.get(`${process.env.NEXT_PUBLIC_SITE_API_LINK}/api/section-pourquoi-choisir-next-step?${whyChooseUsQuery}`)
        .then(res => {
            whyChooseUs =
                res.data.data.attributes
        })
    //endregion

    //region Footer data
    const footerData = await getFooterData()
    //endregion

    //region Instagram
    const apiUrl = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${process.env.INSTAGRAM_TOKEN}`

    let instagramData = null
    /*await axios.get(apiUrl)
               .then(res => {
                   instagramData =
                       res.data.data
               })
               .catch(error => {
                   console.error('Error fetching Instagram images:', error)
               })*/
    //endregion

    return {
        props: {
            data,
            whyChooseUs,
            instagramData,
            footerData,
            time: new Date().toISOString(),
        },
        revalidate: 10,
    }
}
//endregion
