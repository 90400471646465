import cn from 'classnames'
import styles from '@/styles/components/Realisation.module.sass'
import {RealisationSmallType} from '@/models/realisation'
import Link from 'next/link'
import Z_Strapi_Image from '@/components/Z_Strapi_Image'

const Realisation = ({realisation}: {
    realisation: RealisationSmallType
}) => {
    return (
        <Link
            href={`/realisations/${realisation.attributes.slug}`}
            className={cn(
                styles.realisation,
            )}
            scroll={false}
        >
            {/*Image*/}
            <Z_Strapi_Image
                className={styles.realisation_image}
                image={realisation.attributes.image}
                fill={true}
                sizes={'(max-width: 768px) 100vw, 50vw'}
            />

            {/*Title*/}
            <span className={styles.realisation_title}>{realisation.attributes.title}</span>
        </Link>
    )
}

export default Realisation
