import Link from 'next/link'
import styles from '@/styles/components/ButtonLink.module.sass'
import cn from 'classnames'

const ButtonLink = ({
	                    href,
	                    className,
	                    text,
                    }: { href: string, className: string, text: string }) => {
	return (
		<Link
			href={href}
			className={cn(styles.button, className)}
			scroll={false}
		>
			<div className={styles.container}>
				{/*Text*/}
				<span className={styles.text}>{text}</span>
				<span className={styles.hovered_text}>{text}</span>
			</div>
		</Link>
	)
}

export const Button = ({
	                       className,
	                       text,
	                       onClick,
                       }: { className: string, text: string, onClick: () => void }) => {
	return (
		<button
			type={'button'}
			className={cn(styles.button, className)}
			onClick={onClick}
		>
			<div className={styles.container}>
				{/*Text*/}
				<span className={styles.text}>{text}</span>
				<span className={styles.hovered_text}>{text}</span>
			</div>
		</button>
	)
}

export default ButtonLink
