import styles from '@/styles/components/BottomSection.module.sass'
import Z_Strapi_Image from '@/components/Z_Strapi_Image'
import {StrapiImage} from '@/models/strapiImage'
import Markdown from 'marked-react'
import ButtonLink from '@/components/ButtonLink'
import cn from 'classnames'

export type BottomSectionData = {
    title: string
    button_text: string
    button_link: string

    background_image: StrapiImage
}

const BottomSection = ({
                           data,
                           parentStyle,
                       }: {
    data: BottomSectionData,
    parentStyle?: any
}) => {
    return (
        <div className={cn(styles.bottom_section, parentStyle?.bottom_section)}>
            {/*Background image*/}
            <Z_Strapi_Image
                image={data.background_image}
                className={cn(styles.background_image, parentStyle?.background_image)}
                sizes={''}
            />

            {/*Title*/}
            <span
                className={styles.title}
            ><Markdown
                value={data.title}
                breaks={true}
            /></span>

            {/*Link*/}
            <ButtonLink
                href={data.button_link}
                className={styles.link}
                text={data.button_text}
            />
        </div>
    )
}

export default BottomSection
